<template>
    <footer class="text-xs mt-12">
        <div class="max-w-5xl mx-auto w-full my-8 px-8">
            <p class="m-0 text-center">
                <nuxt-link :href="localePath('/cms/imprint')">{{ $t("layouts.footer.imprint") }}</nuxt-link> |
                <nuxt-link :href="localePath('/cms/privacy-policy')">{{ $t("layouts.footer.privacy") }}</nuxt-link> |
                <nuxt-link :href="localePath('/cms/terms-loyalty-card-programm')">{{
                    $t("layouts.footer.terms-loyalty")
                }}</nuxt-link>
                |
                <nuxt-link :href="localePath('/cms/privacy-loyalty-card-programm')">{{
                    $t("layouts.footer.privacy-loyalty")
                }}</nuxt-link>
                |
                <nuxt-link :href="localePath('/faq')">{{ $t("layouts.footer.faq") }}</nuxt-link>
                - <span class="whitespace-nowrap">{{ $t("layouts.footer.copyright", { year: currentYear }) }}</span>
            </p>
        </div>
    </footer>
</template>

<script setup lang="ts">
const localePath = useLocalePath();

const currentYear = new Date().getFullYear();
</script>
